.rdw-option-wrapper {
  border-radius: 100% !important;
  border: 0 !important;
  opacity: 0.5;
  width: 25px !important;
  height: 25px !important;
}

.rdw-option-wrapper:hover {
  box-shadow: unset !important;
  opacity: 0.7;
}

.rdw-option-active {
  opacity: 1 !important;
  box-shadow: unset !important;
}

.rdw-colorpicker-modal-header {
  visibility: hidden !important;
  height: 0px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.rdw-colorpicker-modal-options {
  overflow: unset !important;
}

.rdw-colorpicker-option {
  box-shadow: unset !important;
  opacity: 1 !important;
}

.rdw-colorpicker-cube {
  border: 1px solid #000 !important;
  border-radius: 0 !important;
}

.rdw-colorpicker-modal {
  top: -140px !important;
  box-shadow: 3px 3px 5px #000 !important;
  width: 155px !important;
  height: 125px !important;
  padding: 4px !important;
}

.rdw-colorpicker-modal-options {
  margin: 0 !important;
  padding: 0 !important;
}

.editor-toolbar {
  background-color: unset !important;
  border: 0 !important;
  border-bottom: 1px solid #000;
}

.editor-editor {
  padding-left: 8px;
  padding-right: 8px;
  opacity: 0.999;
}

input:-webkit-autofill {
  transition-delay: 9999999s;
  -webkit-transition-delay: 9999999s;
}

@keyframes heart-pulse {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(0.8);
  }
}

@keyframes textureSparkle {
  0%,
  100% {
    opacity: 0.75;
    background-position: 50% 50%;
    filter: brightness(1.2) contrast(1.25);
  }
  5%,
  8% {
    opacity: 1;
    background-position: 40% 40%;
    filter: brightness(0.8) contrast(1.2);
  }
  13%,
  16% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(1.2) contrast(0.8);
  }
  35%,
  38% {
    opacity: 1;
    background-position: 60% 60%;
    filter: brightness(1) contrast(1);
  }
  55% {
    opacity: 0.33;
    background-position: 45% 45%;
    filter: brightness(1.2) contrast(1.25);
  }
}

@keyframes textureGradient {
  0%,
  100% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(0.5) contrast(1);
  }
  5%,
  9% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(0.75) contrast(1.25);
  }
  13%,
  17% {
    background-position: 0% 0%;
    opacity: 0.88;
  }
  35%,
  39% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(0.5) contrast(1);
  }
  55% {
    background-position: 0% 0%;
    opacity: 1;
    filter: brightness(0.75) contrast(1.25);
  }
}

@keyframes textureCard {
  0%,
  100% {
    transform: rotateZ(0deg) rotateX(0deg) rotateY(0deg);
  }
  5%,
  8% {
    transform: rotateZ(0deg) rotateX(6deg) rotateY(-20deg);
  }
  13%,
  16% {
    transform: rotateZ(0deg) rotateX(-9deg) rotateY(32deg);
  }
  35%,
  38% {
    transform: rotateZ(3deg) rotateX(12deg) rotateY(20deg);
  }
  55% {
    transform: rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg);
  }
}
