::root {
  --gradient-pos: 50% 50%;
  --sparkle-pos: 50% 50%;
  --sparkle-opacity: 1;
}

.card {
  width: 100%;
  height: 100%;

  position: relative;
  overflow: hidden;
  overflow: hidden;
  z-index: 10;
  touch-action: none;

  border-radius: 5% / 3.5%;
  /* box-shadow: -5px -5px 5px -5px #fac, 5px 5px 5px -5px #ddccaa,
    -7px -7px 10px -5px transparent, 7px 7px 10px -5px transparent,
    0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5); */

  transition: transform 0.5s ease, box-shadow 0.2s ease;
  will-change: transform, filter;

  background-color: #040712;
  /* background-image: url(/temp/example.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transform-origin: center;
}

.card:hover {
  box-shadow: -20px -20px 30px -25px #fac, 20px 20px 30px -25px #ddccaa,
    -7px -7px 10px -5px #fac, 7px 7px 10px -5px #ddccaa,
    0 0 13px 4px rgba(255, 255, 255, 0.3), 0 55px 35px -20px rgba(0, 0, 0, 0.5);
}

.card:before,
.card:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-repeat: no-repeat;
  opacity: 0.5;
  mix-blend-mode: color-dodge;
  transition: all 0.33s ease;
}

.card:before {
  background-position: 50% 50%;
  background-size: 300% 300%;
  background-image: linear-gradient(
    115deg,
    transparent 0%,
    #fac 25%,
    transparent 47%,
    transparent 53%,
    #ddccaa 75%,
    transparent 100%
  );
  opacity: 0.5;
  filter: brightness(0.5) contrast(1);
  z-index: 1;
}

.card:after {
  opacity: 1;
  background-image: '', '',
    linear-gradient(
      125deg,
      #ff008450 15%,
      #fca40040 30%,
      #ffff0030 40%,
      #00ff8a20 60%,
      #00cfff40 70%,
      #cc4cfa50 85%
    );
  background-position: 50% 50%;
  background-size: cover, cover;
  /* background-size: 120%; */
  background-blend-mode: overlay;
  z-index: 2;
  filter: brightness(1) contrast(1);
  transition: all 0.33s ease;
  mix-blend-mode: color-dodge;
  opacity: 0.75;
}

.card.active:after,
.card:hover:after {
  filter: brightness(1) contrast(1);
  opacity: 1;
}

.card.active,
.card:hover {
  animation: none;
  transition: box-shadow 0.1s ease-out;
}

.card.active:before,
.card:hover:before {
  animation: none;
  background-image: linear-gradient(
    110deg,
    transparent 25%,
    #fac 48%,
    #ddccaa 52%,
    transparent 75%
  );
  background-position: 50% 50%;
  background-size: 250% 250%;
  opacity: 0.88;
  filter: brightness(0.66) contrast(1.33);
  transition: none;
}

.card.active:before,
.card:hover:before,
.card.active:after,
.card:hover:after {
  animation: none;
  transition: none;
}

.card:hover:before {
  background-position: var(--gradient-pos);
}
.card:hover:after {
  background-position: var(--sparkle-pos);
  opacity: var(--sparkle-opacity);
}
